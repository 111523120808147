var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentTag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: _vm.classes,
          style: {
            background: _vm.uniqueColor || null,
            borderColor: _vm.uniqueColor || null,
            borderColor: _vm.borderColor || null,
            color: _vm.textColor || null,
            pointerEvents: (_vm.notClickable && "none") || null,
          },
          attrs: { tag: _vm.tagAttr, to: _vm.to, exact: _vm.exact },
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.loading ? _vm._t("default") : _c("div", [_vm._v(" ")]),
      _vm._v(" "),
      _vm.loading
        ? _c("CircularSpinner", {
            staticClass: "button__circular-spinner",
            attrs: { inverse: true },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }