var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "circular-spinner",
      class: {
        "circular-spinner--inverse": _vm.inverse,
        "circular-spinner--dark": _vm.dark,
        "circular-spinner--large": _vm.large,
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "65px",
            height: "65px",
            viewBox: "0 0 66 66",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("circle", {
            attrs: {
              fill: "none",
              "stroke-width": _vm.stroke,
              "stroke-linecap": "round",
              cx: "33",
              cy: "33",
              r: "30",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }