<template>
  <component
    :is="componentTag"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
    :tag="tagAttr"
    :to="to"
    :exact="exact"
    :style="{
      background: uniqueColor || null,
      borderColor: uniqueColor || null,
      borderColor: borderColor || null,
      color: textColor || null,
      pointerEvents: (notClickable && 'none') || null,
    }"
  >
    <slot v-if="!loading"></slot>
    <div v-else>&nbsp;</div>
    <CircularSpinner class="button__circular-spinner" :inverse="true" v-if="loading" />
  </component>
</template>

<script>
import CircularSpinner from 'apps/public/pages/components/circular-spinner.vue';

export default {
  name: 'FsButtonMain',

  props: {
    // Button size
    size: {
      type: String,
      validator: (size) => ['nano', 'micro', 'tiny', 'extra-small', 'small', 'large', 'huge'].includes(size),
    },
    // Colors
    // available colors: dark, green, red, white
    color: {
      type: String,
    },
    borderColor: {
      type: String,
    },
    borderStyle: {
      type: String,
      validator: (style) => ['solid', 'dashed'].includes(style),
      default: 'solid',
    },
    textColor: {
      type: String,
    },
    uniqueColor: {
      type: String,
    },
    // Show button in disabled state
    disabled: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    notClickable: {
      type: Boolean,
    },
    // Forcing input-like height and styling
    inputLike: {
      type: Boolean,
      default: false,
    },
    // When loading
    loading: {
      type: Boolean,
      default: false,
    },
    // Use with longer text
    long: {
      type: Boolean,
      default: false,
    },
    // Button style
    // Available styles (except for default styling):
    // ghost - synonym for outlined
    styling: {
      type: String,
      validator: (style) => ['inversed', 'outlined', 'ghost', 'text'].includes(style),
    },
    roundedSides: {
      type: Boolean,
      default: false,
    },
    // The tag to use as the button element (can for example be an anchor)
    tag: {
      type: String,
      // Defaults to button, unless component is treated as a router-link wrapper, in which it defaults to the anchor tag
    },
    transparentBg: Boolean,
    // Themes
    //
    // Available themes (except for default theming):
    // plm
    theme: {
      type: String,
      default: null,
    },
    // Special properties used when using component as a router-link wrapper
    // Pass anything to the to attribute and the component will be treated as a router-link wrapper
    to: {},
    exact: {},
  },

  components: { CircularSpinner },

  inheritAttrs: false,

  mounted() {},

  computed: {
    classes() {
      const classes = ['button'];

      const stylesMap = {
        inversed: 'inversed',
        outlined: 'outlined',
        ghost: 'outlined',
        text: 'text',
      };

      if (this.color) classes.push(`button--${this.color}`);
      if (this.disabled) classes.push(`button--disabled`);
      if (this.inputLike) classes.push(`button--input-like`);
      if (this.loading) classes.push(`button--loading`);
      if (this.long) classes.push(`button--long`);
      if (this.size) classes.push(`button--${this.size}`);
      if (this.styling) classes.push(`button--${stylesMap[this.styling]}`);
      if (this.theme) classes.push(`button--${this.theme}`);
      if (this.roundedSides) classes.push(`button--rounded-sides`);
      if (this.transparentBg) classes.push(`button--transparent-bg`);
      if (this.borderStyle === 'dashed') classes.push(`button--border-dashed`);
      if (this.noOutline) classes.push(`button--no-outline`);

      return classes;
    },
    componentTag() {
      return this.to ? 'router-link' : this.tag || 'button'; // Default to button tag unless router-link wrapper
    },
    tagAttr() {
      return this.to && (this.tag || 'a'); // Default to anchor tag for router-link wrapper scenarios
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'common/styles/variables';
@import 'common/styles/text';

// Inspired by bootstrap - https://github.com/twbs/bootstrap/blob/master/scss/_buttons.scss
.button {
  @include button;
  position: relative;
  background-color: $c-blue;
  border: 1px solid transparent;
  border-radius: $br-light;
  color: $c-light;
  fill: $c-light;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*vertical-align: top;*/ // Removed because it makes it less inline'y. Originally removed due to link--button (see link.scss)
  padding: 0.68em 1.57em;
  user-select: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, opacity 0.1s ease;
  white-space: nowrap;
  background-clip: border-box;

  &:hover {
    background-color: darken($c-blue, 7.5%);
    opacity: 0.7;
  }

  &:active {
    background-color: darken($c-blue, 10%);
  }

  &--gray {
    border-color: #b1b1b1 !important;
    background: #b1b1b1 !important;
  }

  &--white {
    border-color: #fff !important;
    color: #000 !important;
    background: #fff !important;

    ::v-deep svg {
      fill: #000;
    }
  }

  &--dark-blue {
    border-color: #35495e !important;
    background: #35495e !important;
  }

  &--transparent,
  &--transparent-bg {
    background: transparent !important;
  }

  &--border-dashed {
    border-style: dashed !important;
  }

  &--no-outline {
    outline: none;
  }

  // Disabled styling
  &--disabled,
  &:disabled {
    color: rgba($c-light, 0.37);
    background-color: rgba($c-blue, 0.37);

    &:hover,
    &:active {
      background-color: rgba($c-blue, 0.37);
      outline: none;
    }

    opacity: 0.3 !important;
    pointer-events: none !important;
  }

  // PLM theme
  &--plm {
    font-weight: 400;
    background-color: $c-dark;
    border-radius: $br-round-corner;
    padding: 0.57em 1.42em;

    &:hover {
      background-color: lighten($c-dark, 7.5%);
    }

    &:active {
      background-color: lighten($c-dark, 10%);
    }

    &--disabled,
    &:disabled {
      color: rgba($c-light, 0.37);
      background-color: rgba($c-dark, 0.37);

      &:hover,
      &:active {
        background-color: rgba($c-dark, 0.37);
      }
    }

    &.button--inversed {
      background-color: $c-bg-light;
      color: $c-dark;
      fill: $c-dark;

      &:hover {
        background-color: darken($c-bg-light, 7.5%);
      }

      &:active {
        background-color: darken($c-bg-light, 10%);
      }

      &--disabled,
      &:disabled {
        color: rgba($c-dark, 0.37);
        background-color: rgba($c-bg-light, 0.37);

        &:hover,
        &:active {
          background-color: rgba($c-bg-light, 0.37);
        }
      }
    }

    &.button--outlined {
      border-color: $c-dark;
      color: $c-dark;
      fill: $c-dark;
      background: transparent !important;

      &:hover {
        background-color: $c-dark;
        border-color: $c-dark;
      }

      &:active {
        background-color: $c-dark;
        border-color: $c-dark;
      }

      &.button--disabled,
      &:disabled {
        color: rgba($c-dark, 0.37);
        fill: rgba($c-dark, 0.37);
        border-color: rgba($c-dark, 0.37);
      }
    }
  }

  &--outlined {
    background-color: transparent;
    border-color: $c-blue;
    color: $c-blue;
    fill: $c-blue;

    &:hover {
      background-color: $c-blue;
      border-color: $c-blue;
      color: $c-light;
      fill: $c-light;
    }

    &:active {
      background-color: $c-blue;
      border-color: $c-blue;
      color: $c-light;
      fill: $c-light;
    }

    &.button--disabled,
    &:disabled {
      color: rgba($c-blue, 0.37);
      fill: rgba($c-blue, 0.37);
      border-color: rgba($c-blue, 0.37);
      background-color: transparent;
    }
  }

  &--text {
    background-color: transparent;
    color: $c-blue;
    fill: $c-blue;

    &:hover {
      background-color: rgba($c-blue, 0.1);
      border-color: transparent;
    }

    &:active {
      background-color: rgba($c-blue, 0.3);
      border-color: transparent;
    }

    &.button--disabled,
    &:disabled {
      color: rgba($c-blue, 0.37);
      fill: rgba($c-blue, 0.37);
    }

    @mixin color-theme($color) {
      color: $color;
      fill: $color;

      &:hover {
        background-color: rgba($color, 0.1);
      }

      &:active {
        background-color: rgba($color, 0.3);
      }

      &.button--disabled,
      &:disabled {
        color: rgba($color, 0.37);
        fill: rgba($color, 0.37);
      }
    }

    &.button--dark {
      @include color-theme($c-dark);
    }

    &.button--green {
      @include color-theme($c-green);
    }

    &.button--red {
      @include color-theme($c-red);
    }
  }

  // Sizing
  &--nano {
    display: flex;
    height: math.div(1.5rem, 1.6);
    font-size: math.div(1rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--micro {
    display: flex;
    height: math.div(2rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--tiny {
    display: flex;
    height: math.div(2.4rem, 1.6);
    max-height: math.div(2.4rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    font-weight: 400;
    text-transform: none;
  }
  &--extra-small {
    @include button--small;
    min-height: math.div(2.9rem, 1.6);
    max-height: math.div(2.9rem, 1.6);
    padding-top: 9px;
  }
  &--small {
    @include button--small;
    max-height: math.div(3.5rem, 1.6);
  }

  &--large {
    @include button--large;
  }

  &--huge {
    @include button--huge;
  }

  // Special style cases
  &--long {
    text-transform: none;
  }

  &--input-like {
    line-height: 1.58;
    // -1px for border since buttons otherwise looks bigger (at least when in filled mode)
    padding-top: calc(0.5em - 1px);
    padding-bottom: calc(0.5em - 1px);
  }

  // Loading styling
  // Hide text when loading
  &--loading {
    color: transparent;
  }

  &__circular-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  // Rounded sides
  &--rounded-sides {
    border-radius: math.div(10rem, 1.6);
  }
}
</style>
