<template>
  <span
    class="circular-spinner"
    :class="{
      'circular-spinner--inverse': inverse,
      'circular-spinner--dark': dark,
      'circular-spinner--large': large,
    }"
  >
    <svg width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" :stroke-width="stroke" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </span>
</template>

<script>
// Proudly stolen from https://codepen.io/anon/pen/wOKoqQ
// Alternative https://codepen.io/ConAntonakos/pen/ryMaOX

export default {
  name: 'CircularSpinner',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    stroke: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      step: 1,
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';

$offset: 187;
$duration: 1.7s;

.circular-spinner {
  display: inline-block;
  height: 1em;
  width: 1em;

  svg {
    animation: rotator $duration linear infinite;
    width: 100%;
    height: 100%;
  }

  circle {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: $c-blue;
    /*animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;*/
    animation: dash $duration ease-in-out infinite;
  }

  &--inverse {
    circle {
      stroke: $c-light;
    }
  }

  &--dark {
    circle {
      stroke: $c-dark;
    }
  }

  &--large {
    height: 2em;
    width: 2em;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: math.div($offset, 4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

/*@keyframes colors {
    0% {
      stroke: $c-blue;
    }

    25% {
      stroke: $c-blue;
    }

    50% {
      stroke: $c-blue;
    }

    75% {
      stroke: $c-blue;
    }

    100% {
      stroke: $c-blue;
    }
  }*/
</style>
